














































import { Component, Vue } from 'vue-property-decorator'
import LayoutCompactHeaderOnly from '@/layouts/LayoutCompactHeaderOnly.vue'
import StoreInfoService from '@/services/StoreInfoService'

@Component({
  components: {
    LayoutCompactHeaderOnly
  }
})
export default class TimeDeliveryAndDeadline extends Vue {
  public itemsDeliveryTimezone: any = []
  public message: string = ''

  private store_uuid: string | null = localStorage.getItem('store_uuid')

  created() {
    StoreInfoService.getDeliveryTimezone(this.store_uuid).then(
      response => {
        if (response) {
          this.itemsDeliveryTimezone = response.data.items.display_service
        }
      },
      (error: any) => {
        if (error) {
          if (error.response.status === 429) {
            localStorage.clear()
            this.$bvModal.show(`too-many-request`)
          }
          let errorData = error.response.data
          this.message = errorData.message[0].store_uuid[0]
        }
      }
    )
  }
}
